<script setup>
import LayoutFooter from "~/layouts/LayoutFooter.vue";
import LayoutHeader from "~/layouts/LayoutHeader.vue";
import {checkDeviceClientSide, checkDeviceServerSide} from "~/utils/common";
import ModalDownload from "~/components/ModalDownload.vue";
import ModalSizeCharts from "~/components/ModalSizeCharts.vue";
import NotFoundContent from "~/components/not-found/NotFoundContent.vue";
import DrawerMenu from "~/components/DrawerMenu.vue";
import DrawerCart from "~/components/DrawerCart.vue";
import DrawerCartOrderPreview from "~/components/DrawerCartOrderPreview.vue";
import DrawerCartMobileNumberEdit from "~/components/DrawerCartMobileNumberEdit.vue";
import DrawerCartShipmentEdit from "~/components/DrawerCartShipmentEdit.vue";
import ModalLoadingProgress from "~/components/ModalLoadingProgress.vue";
import ModalShare from "~/components/ModalShare.vue";
import DrawerAI from "~/components/DrawerAI.vue";
import DrawerAIMore from "~/components/DrawerAIMore.vue";
import DrawerAIFAQ from "~/components/DrawerAIFAQ.vue";

definePageMeta({
  keepalive: true
})

checkDeviceServerSide()

onMounted(async () => {
  checkDeviceClientSide()
})
</script>

<template>
  <layout-header/>
  <not-found-content/>
  <layout-footer/>

  <modal-download/>
  <modal-size-charts/>
  <drawer-a-i/>
  <drawer-a-i-more/>
  <drawer-a-i-f-a-q/>
  <drawer-menu/>
  <drawer-cart/>
  <drawer-cart-order-preview/>
  <drawer-cart-mobile-number-edit/>
  <drawer-cart-shipment-edit/>
  <modal-loading-progress/>
  <modal-share/>
</template>

<style scoped lang="scss">

</style>
