<script setup>
import {useBaseStore} from "~/stores/base";
import {PATH_HOME} from "~/utils/constants";

const route = useRoute()
const router = useRouter()

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const hash = computed(() => route.hash)
</script>

<template>
  <div class="not-found-content-mobile" v-if="isMobile">
    <img src="@/assets/img/bg-not-found.png" alt="not-found">
    <template v-if="hash === '#expired'">
      <div class="title">This Link is No Longer Available</div>
      <div class="desc">
        Sorry, the shared link is no longer valid. You can reach out to the original sender for a new one.
      </div>
    </template>
    <template v-else>
      <div class="title">Oops, there's nothing here.</div>
      <div class="desc">
        You can return to the homepage or check if the URL is correct. Thanks for your patience!
      </div>
    </template>
    <nuxt-link :to="PATH_HOME" class="btn-secondary">go to homepage</nuxt-link>
  </div>
  <div class="not-found-content-desktop" v-else>
    <img src="@/assets/img/bg-not-found.png" alt="not-found">
    <template v-if="hash === '#expired'">
      <div class="title">This Link is No Longer Available</div>
      <div class="desc">
        Sorry, the shared link is no longer valid. You can reach out to the original sender for a new one.
      </div>
    </template>
    <template v-else>
      <div class="title">Oops, there's nothing here.</div>
      <div class="desc">
        You can return to the homepage or check if the URL is correct. Thanks for your patience!
      </div>
    </template>
    <nuxt-link :to="PATH_HOME" class="btn-secondary">go to homepage</nuxt-link>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.not-found-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: calc(100vh - $mobile-header-height);

  img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    object-fit: contain;
  }

  .title {
    text-align: center;
    font-family: "Alternate Gothic No3 D";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: uppercase;
    padding-top: 8px;
  }

  .desc {
    text-align: center;
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 166.667% */
    max-width: 80%;
  }

  .btn-secondary {
    padding: 12px 32px 10px 32px;
    width: fit-content;
    margin: 27px auto 0 auto;
  }
}

.not-found-content-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  min-height: calc(100vh - $desktop-header-height - $desktop-footer-height);
  padding: 200px 0;

  img {
    width: 155.748px;
    height: 155.748px;
    margin: 0 auto;
    object-fit: contain;
  }

  .title {
    font-family: "Alternate Gothic No3 D";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
    padding-top: 8px;
    text-align: center;
  }

  .desc {
    text-align: center;
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
  }

  .btn-secondary {
    padding: 12px 32px 10px 32px;
    width: fit-content;
    margin: 27px auto 0 auto;
  }
}
</style>
